<template>
  <div style="background-color: #9e83ea">
    <div class="mt-5">
      <table width="100%">
        <tr>
          <td>
            <!--[if !mso]><!-->
            <div class="interactive">
              <!-- <label for="showlabels" class="labeltoggle" ></label>
  <input type="checkbox" id="showlabels"><br> -->
 
              <form>
                <div v-if="!value != 0">
                  <center>
                    <div class="title">Mystery box</div>
                    <br />
                    <div v-if="showbt" class="mt-5">
                      <div
                        v-for="b in 7"
                        @click="open(b)"
                        :key="b"
                        style="display:inline-block !important;"
                      >
                        <div class="gift"></div>
                      </div>
                    </div>
                    <div v-else class="mt-5">
                      <div v-for="(l, i) in list" :key="i">
                        <div class="circle" :style="l.style">{{ l.value }}</div>
                      </div>
                    </div><br>
                    <!-- <div class="btn" @click="tryagain()" v-if="!showbt">Try again</div> -->
                  </center>
                  <!-- <center>
                    <div class="title mt-3">Digit?</div>
                    <br />
                    <div v-if="showunit">
                      <div v-for="b in 6" :key="b">
                        <div class="gift"></div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-for="(l, i) in units" :key="i">
                        <div class="circle">{{ l }}</div>
                      </div>
                    </div>
                    <div class="btn" @click="openunit()" v-if="showunit">
                      Open
                    </div>
                    <div class="btn" @click="tryagainunit()" v-else>
                      Try again
                    </div>
                  </center> -->
                </div>
                <div v-else>
                  <center>
                    <div class="title">Mystery box</div>
                    <br />
                   
                    <div class="mt-5">
                      <div class="circle">{{ value }}</div>
                    </div><br>
                    <!-- <div class="btn" @click="tryagain()" v-if="!showbt">Try again</div> -->
                  </center>
                  
                </div>
              </form>
              <br />
            </div>
            <!--<![endif]-->
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>


<script>
import GiftService from '../services/GiftService.js'

export default {
  name: "App",
  components: {},
  data() {
    return {
      showbt: true,
      width: 0,
      height: 0,
      list: [],
      units: [],
      showunit: true,
      value:0,
      year:0,
      unshuffled:[]
    };
  },
  methods: {
getvalue(){
  var date = new Date()
      this.year = date.getUTCFullYear() +543
      // console.log(year);
      GiftService.getmystery_box(this.year).then((res)=>{
        if (res.data.length != 0) {
          this.value = res.data.mystery
        }
      });

      GiftService.getmystery().then((res=>{
       this.unshuffled = res.data
      }))
},
    save(mystery){
      if (this.value == 0) {
        var mysterys = {
          mystery:mystery,
          year:this.year
        }
        GiftService.createmystery_box(mysterys).then(()=>{
this.getvalue()
        })
        }
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
    open(id) {
      console.log(id);
      let index = id-1
      this.list = this.unshuffled
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

        this.list[index].style = 'background-color:#9867C5'
        // style:'background-color:red'
        // let value = array1.map((x) => x * 2);
        // console.log(index,'index');
        console.log(this.list[index].value);
        this.save(this.list[index].value)
      this.showbt = false;
    },
    tryagain() {
      this.showbt = true;
    },
    tryagainunit() {
      this.showunit = true;
    },
    openunit() {
      let unshuffled = ["Hundred digit", "Hundred digit", "Thousand digit", "Hundred digit", "Thousand digit", "Hundred digit"];
      this.units = unshuffled
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
      // console.log(this.units);
      this.showunit = false;
    },
  },
  mounted() {
    // console.log(window.innerWidth);
    // console.log(window.innerHeight);
    this.width = window.innerWidth / 3.5 + "px";
    // console.log(this.width);
    this.height = window.innerHeight;
    this.str = [
      '<div style="font-size:30px;font-weight:bold;color:white;">Happy Birthday!</div><br><br> <div style="font-size:20px;color:white;">27 แล้วเนอะ ขอให้ 27 ครั้งแรกนี้ทำอะไรราบรื่น ถ้าติดขัดก็ขอให้ผ่านไปได้ <br><br>สุขภาพก็ขอให้ไม่เจ็บไม่ป่วย แกก็อย่าลืมดูแลสุขภาพด้วยล่ะ ออกกำลังกายบ้าง ดื่มน้ำเยอะๆด้วยล่ะ <br><br>ความรักไม่ต้องอวยพรเนอะแกน่าจะไม่เอา สักหน่อยก็ดีเดี๋ยวไม่ครบ ขอให้เจอคนที่ดี ถ้าไม่ดีก็ไม่ต้องมีหรอกเพราะมีเค้าก็ดีมากแล้ว 555555 <br><br>การเงินก็ขอให้มีงานน้อยๆแต่เงินเยอะๆ แล้วก็อยากบอกว่าเค้าซัพพอร์ตแกทุกอย่างขอแค่แกบอกมา แล้วก็ถ้าเจออะไรมาก็อย่าลืมว่าแกยังมีเค้านะ </div><br><br><div style="font-size:20px;color:white;">Final, I’m lucky to have you in my life.</div>',
    ];
   this.getvalue()
  },
};
</script>

<style>
body {
  background-color: #9e83ea !important;
}
div {
  display: inline-block !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}
#typed6 {
  white-space: pre;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
#showlabels:checked ~ form input {
  display: inline-block;
}
input {
  display: none;
}

.labeltoggle {
  width: 30px;
  height: 30px;
  display: block;
  float: right;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}
.labeltoggle::before {
  content: "\1F575"; /* present */
}

body,
.interactive {
  background-color: #9e83ea;
}

.wrapper {
  max-width: 800px;
  margin: 0px auto;
  margin-top: 20px;
  text-align: center;
  background-color: #9e83ea;
}

.gift {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 100px;
  text-align: center;
  margin-left: 10px;
  margin-bottom: 10px;
}
.circle {
  width: 200px;
  height: 200px;
  line-height: 200px;
  border-radius: 50%;
  font-size: 30px;
  color: black;
  text-align: white;
  background: white;
  margin-left: 10px;
  margin-bottom: 10px;
  display: inline-block;
}

.lblcont {
  width: 200px;
  height: 50px;
  opacity: 0;
  overflow: hidden;
  margin-top: -50px;
}

.lblcont label {
  box-sizing: border-box;
  height: 50px;
  display: block;
  position: relative;
  cursor: pointer;
  padding-right: 10px;
}

.lblcont img {
  float: left;
  width: 170px;
  height: 50px;
  background-color: #eeeeee;
}

.gift::before {
  content: "\1F381"; /* present */
  font-size: 70px;
  display: inline;
  width: 200px;
  line-height: 200px;
  margin-top: 10px;
}
input[name="rr"]:checked ~ .gift::before {
  font-size: 70px;
  margin-top: 8px;
}

.opengift::before {
  font-size: 70px;
  display: inline;
  width: 200px;
  line-height: 200px;
  margin-top: 10px;
}
input[name="rr"]:checked ~ .opengift::before {
  font-size: 70px;
  margin-top: 8px;
}

#r1:checked ~ .gift::before {
  /*content:'\1F937'; /* shrug */
  content: "1"; /* confused */
}
#r2:checked ~ .gift::before {
  content: "2"; /* bicycle */
}
#r3:checked ~ .gift::before {
  content: "3"; /* donut */
}

.btn {
  font-family: Helvetica, Arial;
  font-size: 25px;
  width: 200px;
  height: 50px;
  background-color: purple;
  color: white;
  text-align: center;
  border: 2px solid purple;
  border-radius: 8px;
  margin: 20px 0px;
  overflow: hidden;
}

@keyframes shiftlbl {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-300px);
  }
}

.btn .lblinner {
  animation: shiftlbl 0.6s steps(6, end) infinite;
}

.lblinner:hover {
  animation-play-state: paused;
}

.title {
  font-size: 38px;
  font-family: Lobster, Helvetica Neue, Arial;
  color: #ffffff;
  clear: both;
}

.rset {
  width: 200px;
  height: 50px;
  box-sizing: border-box;
  display: block;
  position: relative;
  background-color: green;
  cursor: pointer;
}

input[name="rr"]:checked ~ .btn .rset {
  margin-top: -50px;
}

#showlabels:checked ~ form .lblcont {
  text-align: right;
  opacity: 0.8;
}
</style>

