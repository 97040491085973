import http from "../http-common";

class GiftService {
  createmystery_box(data) {
    return http.post('/mystery_box', data);
  }
  getmystery_boxs() {
    return http.get(`/mystery_box`);
  }
  getmystery_box(id) {
    return http.get(`/mystery_box/` + id);
  }
  updatemystery_box(id, data) {
    return http.put(`/mystery_box/${id}`, data);
  }
  deletemystery_box(id) {
    return http.delete(`/mystery_box/` + id);
  }
  getmystery() {
    return http.get(`/mystery`);
  }
}

export default new GiftService();